import type { ErrorMessage } from '@atlassian/ai-summary';

export const tooMuchContentString =
	'Atlassian Intelligence can’t generate a summary for you right now, as there is too much content to summarize.';
export const notEnoughContentString =
	'Atlassian Intelligence can’t generate a summary for you right now, as there is not enough content to summarize. You can try again later if more is added!';

export enum AIGQLErrors {
	NOT_ENOUGH_CONTENT = 'NOT_ENOUGH_CONTENT',
	TOO_MUCH_CONTENT = 'TOO_MUCH_CONTENT',
	TOO_MANY_REQUEST = 'RATE_LIMIT',
	AUP_VIOLATION = 'AUP_VIOLATION',
	GENERAL_ERROR = 'GENERAL_ERROR',
}

export type AIErrorType = ErrorMessage | AIGQLErrors;

export enum AIErrorLinkType {
	Troubleshooting = 'Troubleshooting',
	RateLimit = 'RateLimit',
	AupViolation = 'AupViolation',
}

export enum ErrorMessageKeys {
	GENERAL_ERROR = 'generalError',
	NOT_ENOUGH_CONTENT = 'notEnoughContent',
	TOO_MUCH_CONTENT = 'tooMuchContent',
	RATE_LIMIT = 'rateLimit',
	AUP_VIOLATION = 'aupViolation',
}

export enum ErrorTypeErrorMessageMap {
	GENERAL_ERROR = ErrorMessageKeys.GENERAL_ERROR,
	NETWORK_ERROR = ErrorMessageKeys.GENERAL_ERROR,
	NO_ANSWER = ErrorMessageKeys.GENERAL_ERROR,
	NO_AGENT = ErrorMessageKeys.GENERAL_ERROR,
	PLUGIN_ERRORED = ErrorMessageKeys.GENERAL_ERROR,
	AI_DISABLED = ErrorMessageKeys.GENERAL_ERROR,
	UNEXPECTED = ErrorMessageKeys.GENERAL_ERROR,
	RATE_LIMIT = ErrorMessageKeys.RATE_LIMIT,
	OPENAI_RATE_LIMIT = ErrorMessageKeys.RATE_LIMIT,
	AUP_VIOLATION = ErrorMessageKeys.AUP_VIOLATION,
	ACCEPTABLE_USE_VIOLATIONS = ErrorMessageKeys.AUP_VIOLATION,
	NOT_ENOUGH_CONTENT = ErrorMessageKeys.NOT_ENOUGH_CONTENT,
	NOT_ENOUGH_CONTENT_ERROR = ErrorMessageKeys.NOT_ENOUGH_CONTENT,
	// typo comes from the backend
	// https://bitbucket.org/atlassian/assistance-service/src/02087c3b6569f042ec338e08e8e0e152c4563dad/src/schema/message_publisher.py#lines-74
	ASWER_UNAVAILABLE_FOR_CONTENT = ErrorMessageKeys.NOT_ENOUGH_CONTENT,
	TOO_MUCH_CONTENT = ErrorMessageKeys.TOO_MUCH_CONTENT,
	BAD_REQUEST = ErrorMessageKeys.GENERAL_ERROR,
}

export enum ErrorMessageKeyErrorLinkMap {
	generalError = AIErrorLinkType.Troubleshooting,
	rateLimit = AIErrorLinkType.RateLimit,
	aupViolation = AIErrorLinkType.AupViolation,
	notEnoughContent = AIErrorLinkType.Troubleshooting,
	tooMuchContent = AIErrorLinkType.Troubleshooting,
}
