import { useState, useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import type { DocNode } from '@atlaskit/adf-schema';

import { adfToMarkdownPlus } from '@atlassian/adf-markdown-transformer';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown';

import { useSessionData } from '@confluence/session-data';

import type {
	BulkIssueADFQuery as BulkIssueADFQueryType,
	BulkIssueADFQueryVariables,
} from './__types__/BulkIssueADFQuery';
import { BulkIssueADFQuery } from './BulkIssueADFQuery.graphql';
import { fetchSuggestedIssues } from './fetchSuggestedIssues';

export type Issue = {
	issue_type: string;
	field_values: {
		Summary: string;
		Description: string;
	};
};

type UseFetchIssuesReturnType = {
	data: {
		issues: Issue[];
		pageIdMap?: IDMap;
	} | null;
	loading: boolean;
	error?: ApolloError | Error;
};

export const useFetchIssues = (contentId: string): UseFetchIssuesReturnType => {
	const [issues, setIssues] = useState<Issue[] | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<ApolloError | Error | undefined>(undefined);
	const [pageIdMap, setPageIdMap] = useState<IDMap | undefined>(undefined);

	const { cloudId } = useSessionData();

	const { data: adfData } = useQuery<BulkIssueADFQueryType, BulkIssueADFQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		BulkIssueADFQuery,
		{
			variables: {
				contentId,
			},
			onError: (adfError) => {
				setLoading(false);
				setError(adfError);
			},
			fetchPolicy: 'network-only',
		},
	);

	const adf = adfData?.singleContent?.body?.atlas_doc_format?.value;

	const makeAiMateRequest = useCallback(
		async (markdownPlus: string) => {
			try {
				const { suggested_issues } = await fetchSuggestedIssues({
					cloudId,
					markdownPlus,
				});
				if (suggested_issues) {
					setIssues(suggested_issues);
				} else {
					setError(new Error('No suggested issues found'));
				}
			} catch (err) {
				setError(err);
			}

			setLoading(false);
		},
		[cloudId],
	);

	useEffect(() => {
		if (adf) {
			try {
				const parsedAdf: DocNode = JSON.parse(adf);
				const { markdown: markdownPlus, idMap } = adfToMarkdownPlus(parsedAdf);
				setPageIdMap(idMap);
				void makeAiMateRequest(markdownPlus);
			} catch (err) {
				setError(new Error('Failed to parse adf'));
				setLoading(false);
			}
		}
	}, [adf, makeAiMateRequest]);

	const data = issues ? { issues, pageIdMap } : null;
	return { data, loading, error };
};
