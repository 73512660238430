import type { FC } from 'react';
import React, { useEffect, useRef, useContext } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, Stack, xcss, Text } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import { useResizingHeight } from '@atlaskit/motion';

import type { AIEventsInstrumentationConfig } from '@atlassian/ai-analytics';
import {
	useAIEventsInstrumentation,
	AIEventsInstrumentationProvider,
} from '@atlassian/ai-analytics';
import { AIBorder, AIStreamLoading } from '@atlassian/ai-components';

import { AISummaryFooter, FeedbackCollectorPropertiesProvider } from '@confluence/quick-summary';
import {
	AI_BULK_ISSUE_CREATE,
	ExperienceFailure,
	ExperienceSuccess,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { AIGQLErrors, AIPageErrorText } from '@confluence/ai-common';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';

import { SingleIssueComponent } from './IssueView';
import type { Issue } from './useFetchIssues';
import { useFetchIssues } from './useFetchIssues';

const FEEDBACK_ENTRYPOINT_ID = 'bfca508d-e2bb-4436-a060-3fa27bdce78f';

const i18n = defineMessages({
	bulkIssueCreateTitle: {
		id: 'ai-content-actions.bulk-issue-create.title',
		defaultMessage: 'Suggested Jira issues',
		description: 'title for suggested issues in the bulk issue create component',
	},
	internalFeedbackMessage: {
		id: 'ai-content-actions.bulk-issue-create.feedback-message',
		defaultMessage:
			'Hello from the Confluence AI team! We’re testing this internally and would love to get your feedback - are these issue suggestions what you would expect? Let us know using the feedback icons below.',
		description:
			'info dialog on the top of the bulk issue create popup notifying users to leave feedback for internal testing',
	},
});

const internalFeedbackStyles = xcss({
	backgroundColor: 'color.background.discovery',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderColor: 'color.border.discovery',
	padding: 'space.100',
	borderRadius: 'border.radius.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ScrollableBody = styled.div({
	fontSize: '16px',
	lineHeight: '24px',
	maxHeight: '500px',
	overflowY: 'auto',
	overflowWrap: 'break-word',
	whiteSpace: 'normal',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::-webkit-scrollbar': {
		width: token('space.100', '8px'),
		background: 'transparent',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'::-webkit-scrollbar-thumb': {
		borderRadius: token('space.100', '8px'),
		backgroundColor: token('color.background.neutral'),
		'&:hover': {
			backgroundColor: token('color.background.neutral.hovered'),
		},
	},
});

const headingWrapper = xcss({
	fontWeight: 'font.weight.medium',
});

const errorWrapper = xcss({
	padding: 'space.200',
	overflowWrap: 'break-word',
	whiteSpace: 'normal',
});

type BulkIssueCreateProps = {
	contentId: string;
};

const BulkIssueCreate: FC<BulkIssueCreateProps> = ({ contentId }) => {
	const { formatMessage } = useIntl();

	const experienceTracker = useContext(ExperienceTrackerContext);

	const { data, loading, error } = useFetchIssues(contentId);
	const issues = data?.issues ?? [];
	const pageIdMap = data?.pageIdMap;

	const {
		trackAIInteractionInit,
		trackAIResultView,
		trackAIInteractionDismiss,
		trackAIResultError,
	} = useAIEventsInstrumentation();

	const loadingRef = useRef(loading);
	useEffect(() => {
		loadingRef.current = loading;
	}, [loading]);

	useEffect(() => {
		trackAIInteractionInit({ attributes: { contentId } });

		experienceTracker.start({
			name: AI_BULK_ISSUE_CREATE,
		});

		return () => {
			if (loadingRef.current) {
				trackAIInteractionDismiss();
			}
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderPopupContent = () => {
		if (loading) return <AIStreamLoading />;

		if (error) {
			trackAIResultError({
				aiErrorMessage: 'AI suggested fields failed to be fetched within BulkIssueCreate component',
				aiErrorCode: 0,
			});
			return (
				<Box xcss={errorWrapper} testId="bulk-issue-create-error">
					<AIPageErrorText errorType={AIGQLErrors.GENERAL_ERROR} source="bulk-issue-create" />
					<ErrorDisplay error={error} />
					<ExperienceFailure name={AI_BULK_ISSUE_CREATE} error={error} />
				</Box>
			);
		}

		trackAIResultView({
			attributes: {
				issuesGenerated: issues.length,
			},
		});
		return (
			<>
				<Box padding="space.200">
					<ScrollableBody>
						<Stack space="space.200">
							<Box xcss={internalFeedbackStyles}>
								<Text>{formatMessage(i18n.internalFeedbackMessage)}</Text>
							</Box>
							<Heading size="medium">
								<Box xcss={headingWrapper}>{formatMessage(i18n.bulkIssueCreateTitle)}</Box>
							</Heading>
							{issues.map((issue: Issue) => (
								<SingleIssueComponent
									key={issue.field_values.Summary}
									issue={issue}
									pageIdMap={pageIdMap}
								/>
							))}
						</Stack>
					</ScrollableBody>
				</Box>
				<AISummaryFooter
					analyticsProperties={{
						source: 'bulkIssueCreate',
					}}
					contentId={contentId}
					showBetaLozenge
				/>
				<ExperienceSuccess name={AI_BULK_ISSUE_CREATE} />
			</>
		);
	};

	return (
		<AIBorder loading={loading}>
			<div {...useResizingHeight()}>{renderPopupContent()}</div>
		</AIBorder>
	);
};

const BulkIssueCreateWithAnalytics = (props: BulkIssueCreateProps) => {
	const aiAnalyticsConfig: AIEventsInstrumentationConfig = {
		product: 'confluence',
		subproduct: 'confluence',
		source: 'confluencePage',
		aiFeatureName: 'bulkIssueCreate',
		proactiveGeneratedAI: 0,
		userGeneratedAI: 1,
	};

	return (
		<AIEventsInstrumentationProvider config={aiAnalyticsConfig}>
			<FeedbackCollectorPropertiesProvider entrypointId={FEEDBACK_ENTRYPOINT_ID}>
				<ErrorBoundary attribution={Attribution.SMARTS}>
					<BulkIssueCreate {...props} />
				</ErrorBoundary>
			</FeedbackCollectorPropertiesProvider>
		</AIEventsInstrumentationProvider>
	);
};

export { BulkIssueCreateWithAnalytics as BulkIssueCreate };
