import React, { type FC } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';

import type { BoxProps } from '@atlaskit/primitives';

import type { AIErrorType } from '../__types__/aiErrors';
import {
	AIErrorLinkType,
	ErrorTypeErrorMessageMap,
	ErrorMessageKeyErrorLinkMap,
} from '../__types__/aiErrors';

import { AIErrorText, GenericErrorMessages } from './AIErrorText';

const i18n: Record<string, MessageDescriptor> = {
	...GenericErrorMessages,
	...defineMessages({
		tooMuchContent: {
			id: 'ai-common.page-error.too-much-content',
			defaultMessage:
				'Atlassian Intelligence can’t generate a summary for you right now, as there is too much content to summarize.',
			description: 'error message when there is too much content to summarize',
		},
		aupViolation: {
			id: 'ai-common.page-error.aup-violation',
			defaultMessage:
				'Your page content might not comply with our Acceptable Use Policy. Please review and refer to our <a>Acceptable Use Policy</a> if needed.',
			description: "error message when the content fails Atlassian's acceptable use policy",
		},
	}),
};

type AIPageErrorProps = BoxProps<'p'> & {
	errorType: AIErrorType;
	source: string;
	analyticsAttributes?: Record<string, any>;
};

export const AIPageErrorText: FC<AIPageErrorProps> = ({
	errorType,
	source,
	analyticsAttributes,
	...props
}) => {
	const errorMessageKey = ErrorTypeErrorMessageMap[errorType];
	const errorMessage = i18n[errorMessageKey];

	const linkType = AIErrorLinkType[ErrorMessageKeyErrorLinkMap[errorMessageKey]];

	return (
		<AIErrorText
			errorMessage={errorMessage}
			linkType={linkType}
			source={source}
			analyticsAttributes={analyticsAttributes}
			{...props}
		/>
	);
};
